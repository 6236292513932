<template>
  <div class="lottery-wheel">
    <div class="lottery-wheel-title">
      <div class="inner">{{ title }}</div>
      <div class="star star-1"></div>
      <div class="star star-2"></div>
      <div class="star star-3"></div>
    </div>
    <div class="wheel-container">
      <div class="wheel-bg" :style="rotateStyle">
        <div class="grids">
          <div
            v-for="(item, index) in rewards"
            :key="index"
            :class="[`grids-item-${index}`]"
            class="grids-item"
          >
            <img :src="item.img" alt="" class="grids-item__img" />
            <div class="grids-item__name van-ellipsis">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="wheel-pointer" @click="start">开始</div>
    </div>
    <div class="tips-wrap">
      <div class="tips-item">{{ lastTimes === -1 ? '不限次数' : `${lastTimes}次机会` }}</div>
      <div class="tips-item">有效时间：{{ validMsg }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { getLotteryInfo, doLottery } from '@/api/lottery'
import { goOldAuth } from '@/utils/adapter'
import { useStore } from '@/store'
import { Toast } from 'vant'

const animateDuration = 8000

export default defineComponent({
  name: 'LotteryWheel',
  emits: ['opened'],
  setup(props, context) {
    // const { emit } = useContext()
    const route = useRoute()
    const store = useStore()
    // 奖项列表
    const rewards = ref<Recordable[]>([])
    const angles = ref(
      Array(8)
        .fill(0)
        .map((item, index) => 45 * index + 22.5)
    )
    const title = ref('')
    // 剩余抽奖次数
    const lastTimes = ref(0)
    // 有效期
    const validMsg = ref('')
    // 旋转锁，正在旋转时为true，此时用户无法再次触发旋转
    const locked = ref(false)
    // 旋转角度
    const rotateAngle = ref(0)
    // 1后台设置了分享不增加抽奖次数 0相反
    const noShare = ref(0)

    const rotateStyle = computed(() => {
      return {
        // transition: 'transform 5000ms ease-in-out',
        transition: `transform ${animateDuration}ms cubic-bezier(.37, 0, 0, 1)`,
        transform: `rotate(${rotateAngle.value}deg)`,
      }
    })

    const fetchInfo = () => {
      const _type = 2 // 1PC端 2移动端
      getLotteryInfo(route.query.code as string, _type)
        .then((res) => {
          const { data } = res.data
          validMsg.value = data.validMsg
          title.value = data.luckyDrawSlogen
          lastTimes.value = Number(data.participationConstraint)
          rewards.value = data.luckyDrawPrizeList.map((item: any) => {
            return {
              id: item.luckyDrawPrizeId,
              name: item.prizeName,
              img: item.prizeImg,
            }
          })
          noShare.value = data.noShare
        })
        .catch(() => {})
    }

    // 开始抽奖
    const start = () => {
      if (!store.state.auth.token) {
        console.log('未登录')
        goOldAuth()
        return
      }
      if (lastTimes.value === 0) {
        // Toast('抽奖次数已用完啦，分享活动可再获得抽奖机会哦～')
        Toast(
          noShare.value == 1 ? '抽奖次数已用完啦' : '抽奖次数已用完啦，分享活动可再获得抽奖机会哦～'
        )
        return
      }
      if (locked.value) {
        console.log('抽奖中，请您耐心等待')
        return
      }
      locked.value = true
      // const angle = rotateAngle.value + 10 * 360 + 22.5 - (rotateAngle.value % 360)
      // rotateAngle.value = angle // 保存此次旋转角度，下次旋转需要用到
      requestReward().then((res) => {
        rotate(reward2Angle(res.id))
        setTimeout(() => {
          locked.value = false // 本次抽奖结束，可以进行下一次
          console.log('抽中了！')
          context.emit('opened', res)
        }, animateDuration)
      })
    }

    // 请求奖品
    const requestReward = (): Promise<{
      luckyDrawWinningRecordLogId: number
      id: number
      type: number
      name: string
    }> => {
      return doLottery(route.query.code as string)
        .then((res) => {
          const { data } = res.data
          lastTimes.value = Number(data.remainingNumber)
          return {
            luckyDrawWinningRecordLogId: data.luckyDrawWinningRecordLogId as number,
            id: data.luckyDrawPrizeId as number,
            type: data.prizeType as number,
            name: data.prizeName as string,
          }
        })
        .catch(() => {
          locked.value = false
          return Promise.reject()
        })
    }

    // 抽中的奖品换算成对应的最小角度
    const reward2Angle = (id: number): number => {
      const index = rewards.value.findIndex((item) => item.id === id)
      console.log('抽中的奖品对应的角度是', angles.value[index])
      return angles.value[index]
    }

    // 旋转
    const rotate = (angle: number, turns = 10) => {
      rotateAngle.value = rotateAngle.value + turns * 360 + angle - (rotateAngle.value % 360)
    }

    fetchInfo()

    return {
      rewards,
      angles,
      title,
      lastTimes,
      validMsg,
      locked,
      rotateAngle,
      noShare,
      rotateStyle,
      fetchInfo,
      start,
      requestReward,
      reward2Angle,
      rotate,
    }
  },
})
</script>

<style lang="less" scoped>
.lottery-wheel {
  text-align: center;
  &-title {
    position: relative;
    display: inline-block;
    width: auto;
    margin-bottom: 34px;
    padding: 0 28px 0 24px;
    line-height: 55px;
    font-size: 40px;
    color: #fd6826;
    .star {
      position: absolute;
      background: url(./star.png) no-repeat center;
      background-size: 100%;
    }
    .star-1 {
      width: 23px;
      height: 24px;
      left: 0;
      bottom: 0;
    }
    .star-2 {
      width: 25px;
      height: 26px;
      top: -8px;
      right: 8px;
    }
    .star-3 {
      width: 17px;
      height: 16px;
      top: 15px;
      right: 0px;
    }
  }

  .wheel-container {
    position: relative;
    width: 640px;
    height: 640px;
    margin: 0 auto;
    .wheel-bg {
      position: relative;
      width: 640px;
      height: 640px;
      background: url(./wheel.png) no-repeat center;
      background-size: 100%;
      .grids {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 552px;
        height: 552px;
        // function getWidth() {
        //     const r = 552 / 2
        //     const angle = 22.5
        //     const ratio = Number(Math.sin(angle * (Math.PI * 2 / 360)).toFixed(2))
        //     const d = r * ratio
        //     const w = 2 * d
        //     return w
        // }
        &-item {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%);
          width: 210px; // getWidth()  209.76 => 210
          height: 276px; // 552 / 2
          transform-origin: top center;
          text-align: center;
          &:nth-child(odd) {
            color: #fff;
          }
          // &-0 {
          //   transform: translateX(-50%) rotate(22.5deg);
          // }
          // &-1 {
          //   transform: translateX(-50%) rotate(67.5deg);
          // }
          // &-2 {
          //   transform: translateX(-50%) rotate(112.5deg);
          // }
          // &-3 {
          //   transform: translateX(-50%) rotate(157.5deg);
          // }
          // &-4 {
          //   transform: translateX(-50%) rotate(202.5deg);
          // }
          // &-5 {
          //   transform: translateX(-50%) rotate(247.5deg);
          // }
          // &-6 {
          //   transform: translateX(-50%) rotate(292.5deg);
          // }
          // &-7 {
          //   transform: translateX(-50%) rotate(337.5deg);
          // }
          &-0 {
            transform: translateX(-50%) rotate(157.5deg);
          }
          &-1 {
            transform: translateX(-50%) rotate(112.5deg);
          }
          &-2 {
            transform: translateX(-50%) rotate(67.5deg);
          }
          &-3 {
            transform: translateX(-50%) rotate(22.5deg);
          }
          &-4 {
            transform: translateX(-50%) rotate(337.5deg);
          }
          &-5 {
            transform: translateX(-50%) rotate(292.5deg);
          }
          &-6 {
            transform: translateX(-50%) rotate(247.5deg);
          }
          &-7 {
            transform: translateX(-50%) rotate(202.5deg);
          }
          &__img {
            margin-top: 140px;
            width: 80px;
            height: 80px;
          }
          &__name {
            margin: 0 auto;
            font-size: 24px;
            width: 90%;
          }
        }
      }
    }

    .wheel-pointer {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 170px;
      height: 170px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: url(./pointer.png) no-repeat center;
      background-size: 100%;
      font-size: 42px;
      color: #fff;
    }
  }

  .tips-wrap {
    margin-top: 20px;
    .tips-item {
      margin-top: 4px;
      line-height: 32px;
      font-size: 24px;
      color: #666;
    }
  }
}
</style>
