
import { defineComponent, PropType } from 'vue'
import { getShopUrl } from '@/utils/adapter'

export default defineComponent({
  name: 'Empty',

  props: {
    message: {
      type: String as PropType<string>,
      default: '暂无数据',
    },
  },

  setup() {
    const go = () => {
      // location.href = 'https://xunfei.cn/h5'
      const url = getShopUrl()
      location.href = url
    }
    return {
      go,
    }
  },
})
