// /**
//  * 把双引号转义成反斜杠加双引号，即 `"` 转义成 `\"`
//  * @param str
//  */
// export function escapeQuote(str: string): string {
//   return str.replace(/\"/g, '\\"')
// }

// /**
//  * 移除字符串中的反斜杠 `\`
//  * @param str
//  */
// export function removeBackslash(str: string): string {
//   return str.replace(/\\/g, '')
// }

// 解析 url 中的参数
export const transformUrl = (str: string) => {
  // console.log(str)
  // 创建目标对象
  const request: any = {}
  // 截取数据字符串
  // const data: string = str.slice(str.indexOf('?') + 1, str.length)
  const data: string = str.slice(str.indexOf('?'), str.length)
  // console.log(data)
  // 将数据字符串表现为数组
  const aParams = data.substr(1).split('&')
  for (let i = 0; i < aParams.length; i++) {
    // 数据属性与数值分离
    const aParam = aParams[i].split('=')
    // 使用属性括号进行属性赋值
    request[aParam[0]] = aParam[1]
  }
  console.log('request', request)
  return request
}

/**
 * 复制到剪贴板
 * @param value 要复制的字符串
 */
export function copyToClipboard(value: string): Promise<boolean> {
  const input = document.createElement('textarea')
  input.style.position = 'absolute'
  input.style.top = '9999px'
  document.body.appendChild(input)
  input.value = value
  input.select()
  document.execCommand('copy')
  document.body.removeChild(input)
  return Promise.resolve(true)
}

/* 存储localStorage
 * @param {*} key
 * @param {*} value
 */
export const setLocalStorage = (key: any, value: any) => {
  window.localStorage.setItem(key, JSON.stringify(value))
}
/**
 * 生成随机字符串
 * @param {*} crypto 传入 crypto-js 库
 */
export const randomStr = (crypto: any) => {
  return crypto.MD5(new Date().toString() + Math.random()).toString()
}
