import axios, { AxiosRequestConfig, AxiosInstance, AxiosResponse, AxiosError } from 'axios'
import config from './config'
import { ResponseCode } from '@/constants/http'
import { Toast } from 'vant'
import { getToken } from '@/utils/auth'
import { goOldAuth } from '@/utils/adapter'

const service: AxiosInstance = axios.create(config)

service.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = getToken()
    if (token) {
      config.headers['X-ECAPI-Authorization'] = token
    }
    return config
  },
  (error: AxiosError) => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (res: AxiosResponse) => {
    if (res.data.code !== ResponseCode.Success) {
      if (res.data.code === ResponseCode.InvalidToken) {
        // console.log(12112)
        // 去老ifly-mall-h5进行授权
        goOldAuth()
        // token失效
      } else {
        // 非token失效的接口提示接口错误信息
        Toast(res.data.msg)
      }
      return Promise.reject(res)
    }
    // 直接return AxiosResponse而不是return AxiosResponse.data.data，这样可以在某些业务场景下获取到更多信息，如AxiosResponse.headers
    // 但是在业务代码里可以放心的直接使用res.data.data获取数据，而不用判断res.data.code，因为其余的code已经直接被rejected了
    return res
  },
  (error: AxiosError) => {
    // 网络层面错误，如接口地址写错了会走到这里
    Toast(error.response?.statusText || error.message || '网络错误')
    return Promise.reject(error)
  }
)

export default service
