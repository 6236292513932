import { InjectionKey } from 'vue'
import {
  createStore,
  useStore as baseUseStore,
  Store,
  StoreOptions,
  createLogger,
  Plugin,
} from 'vuex'
import { RootState } from './types'
import { auth } from './modules/auth'
import createPersistedState from 'vuex-persistedstate'
import { STORAGE_KEY } from '@/constants'

export const key: InjectionKey<Store<RootState>> = Symbol('key')

const plugins: Plugin<RootState>[] = [
  createPersistedState({
    key: STORAGE_KEY,
  }),
]
if (process.env.NODE_ENV === 'development') {
  plugins.push(createLogger())
}

export const storeOptions: StoreOptions<RootState> = {
  plugins,
  modules: {
    auth,
  },
}

export const store = createStore<RootState>(storeOptions)

export function useStore() {
  return baseUseStore(key)
}
