import request from '@/request'

/**
 * 抽奖活动页基本信息接口
 * @param code 活动code
 */
export const getLotteryInfo = (code: string, type: number) =>
  request.get(`/marketing/lucky/draw/index/${code}/${type}`)

/**
 * 获取奖品列表
 * @param code 活动code
 */
export const getPrizeList = (code: string) =>
  request.get(`/marketing/lucky/draw/index/prize/info/${code}`)

/**
 * 获取最新前九条中奖记录接口
 * @param configId 活动主键标志
 */
export const getRecentWinRecords = (configId: string) =>
  request.get(`/marketing/lucky/draw/get/newest/winning/${configId}`)

/**
 * 抽奖操作
 * @param code
 */
export const doLottery = (code: string) =>
  request.get(`/marketing/lucky/draw/operate/lottery/${code}`)

/**
 * 获取我的奖品列表
 * @param code
 */
export const getMyRewards = (paramas: Recordable) =>
  request.post(`/marketing/lucky/draw/my/draw/log/page`, paramas)

// 获取省市区数据
export const getAreasData = () => request.get(`/member/userAddress/code/list`)

// 兑换奖品
export const exchangePrize = (paramas: Recordable) =>
  request.post(`/marketing/lucky/draw/immediately/prize`, paramas)

// 分享获取额外抽奖次数
export const lotteryShareGetChance = (code: string) =>
  request.get(`/marketing/lucky/draw/share/${code}`)
