
import { defineComponent, ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { getLotteryInfo, doLottery } from '@/api/lottery'
import { goOldAuth } from '@/utils/adapter'
import { useStore } from '@/store'
import { Toast } from 'vant'

const animateDuration = 8000

export default defineComponent({
  name: 'LotteryWheel',
  emits: ['opened'],
  setup(props, context) {
    // const { emit } = useContext()
    const route = useRoute()
    const store = useStore()
    // 奖项列表
    const rewards = ref<Recordable[]>([])
    const angles = ref(
      Array(8)
        .fill(0)
        .map((item, index) => 45 * index + 22.5)
    )
    const title = ref('')
    // 剩余抽奖次数
    const lastTimes = ref(0)
    // 有效期
    const validMsg = ref('')
    // 旋转锁，正在旋转时为true，此时用户无法再次触发旋转
    const locked = ref(false)
    // 旋转角度
    const rotateAngle = ref(0)
    // 1后台设置了分享不增加抽奖次数 0相反
    const noShare = ref(0)

    const rotateStyle = computed(() => {
      return {
        // transition: 'transform 5000ms ease-in-out',
        transition: `transform ${animateDuration}ms cubic-bezier(.37, 0, 0, 1)`,
        transform: `rotate(${rotateAngle.value}deg)`,
      }
    })

    const fetchInfo = () => {
      const _type = 2 // 1PC端 2移动端
      getLotteryInfo(route.query.code as string, _type)
        .then((res) => {
          const { data } = res.data
          validMsg.value = data.validMsg
          title.value = data.luckyDrawSlogen
          lastTimes.value = Number(data.participationConstraint)
          rewards.value = data.luckyDrawPrizeList.map((item: any) => {
            return {
              id: item.luckyDrawPrizeId,
              name: item.prizeName,
              img: item.prizeImg,
            }
          })
          noShare.value = data.noShare
        })
        .catch(() => {})
    }

    // 开始抽奖
    const start = () => {
      if (!store.state.auth.token) {
        console.log('未登录')
        goOldAuth()
        return
      }
      if (lastTimes.value === 0) {
        // Toast('抽奖次数已用完啦，分享活动可再获得抽奖机会哦～')
        Toast(
          noShare.value == 1 ? '抽奖次数已用完啦' : '抽奖次数已用完啦，分享活动可再获得抽奖机会哦～'
        )
        return
      }
      if (locked.value) {
        console.log('抽奖中，请您耐心等待')
        return
      }
      locked.value = true
      // const angle = rotateAngle.value + 10 * 360 + 22.5 - (rotateAngle.value % 360)
      // rotateAngle.value = angle // 保存此次旋转角度，下次旋转需要用到
      requestReward().then((res) => {
        rotate(reward2Angle(res.id))
        setTimeout(() => {
          locked.value = false // 本次抽奖结束，可以进行下一次
          console.log('抽中了！')
          context.emit('opened', res)
        }, animateDuration)
      })
    }

    // 请求奖品
    const requestReward = (): Promise<{
      luckyDrawWinningRecordLogId: number
      id: number
      type: number
      name: string
    }> => {
      return doLottery(route.query.code as string)
        .then((res) => {
          const { data } = res.data
          lastTimes.value = Number(data.remainingNumber)
          return {
            luckyDrawWinningRecordLogId: data.luckyDrawWinningRecordLogId as number,
            id: data.luckyDrawPrizeId as number,
            type: data.prizeType as number,
            name: data.prizeName as string,
          }
        })
        .catch(() => {
          locked.value = false
          return Promise.reject()
        })
    }

    // 抽中的奖品换算成对应的最小角度
    const reward2Angle = (id: number): number => {
      const index = rewards.value.findIndex((item) => item.id === id)
      console.log('抽中的奖品对应的角度是', angles.value[index])
      return angles.value[index]
    }

    // 旋转
    const rotate = (angle: number, turns = 10) => {
      rotateAngle.value = rotateAngle.value + turns * 360 + angle - (rotateAngle.value % 360)
    }

    fetchInfo()

    return {
      rewards,
      angles,
      title,
      lastTimes,
      validMsg,
      locked,
      rotateAngle,
      noShare,
      rotateStyle,
      fetchInfo,
      start,
      requestReward,
      reward2Angle,
      rotate,
    }
  },
})
