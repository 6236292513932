<template>
  <!-- <div class="coupon">
    <div class="left">
      <div class="left-t">
        <div class="price">
          <span v-if="isFullReduction">￥</span>
          {{ preferential }}
        </div>
        <div class="condition">{{ threshold }}</div>
      </div>
      <div class="left-b">有效期：{{ validity }} </div>
    </div>
    <div class="right">
      <div class="tit">适用产品</div>
      <div class="goods">
        <template v-if="applicableProducts?.length">
          <div v-for="(item, index) in applicableProducts" :key="index" class="goods-item">{{
            item
          }}</div>
        </template>
        <div v-else class="goods-item">全品类</div>
      </div>
    </div>
  </div> -->
  <div class="coupon">
    <div class="coupon-left">
      <div class="price">{{ preferential }}</div>
      <div class="condition">{{ threshold }}</div>
    </div>
    <div class="coupon-right">
      <div class="name">{{ couponName }}</div>
      <div class="time">有效期：{{ validity }} </div>
      <div class="goods">适用产品：{{ applicableProducts[0] }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import { CouponType } from './types'

export default defineComponent({
  name: 'Coupon',

  props: {
    preferentialType: {
      type: String as PropType<CouponType>,
    },
    preferential: {
      type: [Number, String] as PropType<number | string>,
    },
    threshold: {
      type: String as PropType<string>,
    },
    validity: {
      type: String as PropType<string>,
    },
    applicableProducts: {
      type: Array as PropType<string[]>,
    },
    couponName: {
      type: String as PropType<string>,
    },
  },

  setup(props) {
    // 优惠券类型是否为满减券
    const isFullReduction = computed(() => props.preferentialType === CouponType.fullReduction)

    return {
      isFullReduction,
    }
  },
})
</script>

<style lang="less" scoped>
@import url(~@/assets/styles/mixins.less);
.coupon {
  display: flex;
  width: 690px;
  height: 172px;
  box-shadow: 0px 4px 8px 0px rgba(204, 204, 204, 0.5);
  &-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 226px;
    height: 100%;
    background: url('~@/components/Coupon/coupon_bg@3x.png') no-repeat center;
    background-size: 100%;
    color: #fff;
    .price {
      margin-bottom: 10px;
      font-size: 50px;
    }
    .condition {
      font-size: 24px;
    }
  }
  &-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    height: 100%;
    padding-left: 30px;
    .name {
      font-size: 36px;
      color: #4f4f4f;
    }
    .time,
    .goods {
      margin-top: 5px;
      font-size: 24px;
      color: #969595;
    }
  }
}
// .coupon {
//   display: flex;
//   width: 690px;
//   // height: 130px;
//   background: linear-gradient(45deg, #ff4f4f 0%, #f86c8b 100%);
//   box-shadow: 0px 2px 8px 0px #ffc6c9;
//   border-radius: 10px;
//   color: #fff;
//   .left {
//     position: relative;
//     display: flex;
//     flex-shrink: 0;
//     flex-direction: column;
//     justify-content: center;
//     width: 335px;
//     padding-left: 30px;
//     margin: 22px 0;
//     &::after {
//       position: absolute;
//       display: block;
//       content: '';
//       width: 0px;
//       height: 100%;
//       border-right: 1px dashed #fff;
//       right: 0;
//       top: 50%;
//       transform: translateY(-50%);
//       opacity: 0.8;
//     }
//     .left-t {
//       display: flex;
//       align-items: center;
//       .price {
//         font-size: 42px;
//         span {
//           font-size: 32px;
//         }
//       }
//       .condition {
//         margin-left: 10px;
//         font-size: 24px;
//       }
//     }
//     .left-b {
//       font-size: 20px;
//     }
//   }
//   .right {
//     padding: 16px 38px;
//     width: 355px;
//     min-height: 120px;
//     .tit {
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       width: 117px;
//       height: 32px;
//       background: #fff;
//       border-radius: 20px;
//       font-size: 20px;
//       color: #f96780;
//     }
//     .goods {
//       margin-top: 14px;
//       font-size: 20px;
//       line-height: 28px;
//       &-item {
//         margin-bottom: 5px;
//         width: 230px;
//         .single-ellipsis();
//       }
//     }
//   }
// }
</style>
