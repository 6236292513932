import { STORAGE_KEY } from '@/constants'
import { useStore } from '@/store'
import { MutationTypes as AuthMutationTypes } from '@/store/modules/auth/types'
import { transformUrl } from '@/utils'
// 用来与 ifly-mall-h5 通信的逻辑
export const OLD_STORAGE_KEY = 'ecm'

export const setAuth = (auth: any) => {
  const local = JSON.parse(localStorage.getItem(OLD_STORAGE_KEY) as string)
  if (local === null) {
    // 如果没有store记录，则只保存auth模块信息
    localStorage.setItem(OLD_STORAGE_KEY, JSON.stringify({ auth }))
  } else {
    // 如果已经有store记录,则只替换auth模块信息
    local.auth = auth
    localStorage.setItem(OLD_STORAGE_KEY, JSON.stringify(local))
  }
}
// 同步老 ifly-mall-h5  登录信息到 ifly-mall-webapp
export const syncAuth = () => {
  const store = useStore()
  // console.log("store:",store,useStore())
  const local = JSON.parse(localStorage.getItem(OLD_STORAGE_KEY) as string)
  const query = transformUrl(location.href)
  // 如果是来自中国银行的，则把 boc=1这个参数保存下来
  localStorage.setItem('IFLY_MALL_APP_BOC', query.boc)
  // ifly-mall-webapp 没有登录过，且老 ifly-mall-h5  登录了，此时同步老 ifly-mall-h5  登录信息到 ifly-mall-webapp
  if ((!store.state.auth.token || query.from === 'owa') && local) {
    console.log(1, true)
    console.log("local",local.auth)
    store.commit(AuthMutationTypes.LOGIN, local.auth)
  }
}
// 去老ifly-mall-h5进行授权
export const goOldAuth = () => {
  const { origin, host } = location
  // const boc = localStorage.getItem('IFLY_MALL_APP_BOC')
  // let commonPath = ''
  // if (boc == '1') {
  //   // 如果是中国银行
  //   commonPath = `/h5/boc.html#/profile`
  // } else {
  //   commonPath = `/h5/#/quickLogin?fromwebapp=1&redirect=${encodeURIComponent(location.href)}`
  // }
  // if (host === 'mall.iflysec.com') {
  //   // 测试环境
  //   location.href = `${origin}/ifly-mall/ecshop${commonPath}`
  // } else {
  //   // 正式环境
  //   location.href = `${origin}${commonPath}`
  // }
  // 是否测试环境（不是测试环境，就是正式环境，目前就区分这两个环境）
  const isTestEnv = host === 'mall.iflysec.com'
  const isBoc = localStorage.getItem('IFLY_MALL_APP_BOC') === '1'
  /**
   * test: http(s)://mall.iflysec.com/ifly-mall/ecshop
   * prod: https://m.xunfei.cn
   */
  const prefix = isTestEnv ? `${origin}/ifly-mall/ecshop` : origin
  let loginPath = ''
  if (isBoc) {
    // 如果是中国银行，跳转到钟过银行登录页
    loginPath = `/h5/boc.html#/bocLogin?fromwebapp=1&redirect=${encodeURIComponent(location.href)}`
    if (isTestEnv) {
      // 测试环境需要加merId才能登录
      loginPath += `&merId=10080`
    }
  } else {
    loginPath = `/h5/#/quickLogin?fromwebapp=1&redirect=${encodeURIComponent(location.href)}`
  }
  location.href = prefix + loginPath
}

/**
 * 获取商城h5 url，区分中国银行环境 & 普通环境，区分测试环境 & 线上环境
 * @returns 
 */
export const getShopUrl = () => {
  const { origin, host } = location
  const boc = localStorage.getItem('IFLY_MALL_APP_BOC')
  let url = ''
  if (boc == '1') {
    // 如果是中国银行
    url = `/h5/boc.html#/`
  } else {
    url = `/h5/#/`
  }
  if (host === 'mall.iflysec.com') {
    // 测试环境
    url = `${origin}/ifly-mall/ecshop${url}`
  } else {
    // 正式环境
    url = `${origin}${url}`
  }
  return url
}

export const testLoginFromEcm = () => {
  const auth = {
    flag: false,
    from: '',
    isOnline: true,
    isTokenInvalid: false,
    token:
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOjE5NjMwOTExLCJidXNpbmVzcyI6IkMiLCJwcm9qZWN0IjoiIiwic291cmNlIjoiSDUiLCJleHAiOjE2MjE0OTAxNjgsInBsYXRmb3JtIjoibW96aWxsYSJ9.YcQHkMwddo-Wm8RpPdi4ZeOIjj-VL_LzRIF0UP7p0BQ',
    user: {
      age: null,
      avatar:
        'https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTKQjMNJjfdQgzLbCXupOu3CPo83HzGUdnAL0w3oEDNK3mUP3fbuZGJ9TuyBTMJuiazH9H0SMfwDccw/132',
      gender: 1,
      head_img:
        'https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTKQjMNJjfdQgzLbCXupOu3CPo83HzGUdnAL0w3oEDNK3mUP3fbuZGJ9TuyBTMJuiazH9H0SMfwDccw/132',
      id: 19630911,
      is_affiliate: 0,
      is_auth: false,
      is_completed: true,
      joined_at: 1598232509,
      mobile: '18010855044',
      mobile_binded: false,
      nickname: 'Miami Night',
      rank: {
        desc: '100%',
        name: 'F1',
        score_max: 1000,
        score_min: 0,
      },
      username: 'Miami Night',
    },
  }
  console.log('auth', auth)
  localStorage.setItem(OLD_STORAGE_KEY, JSON.stringify({ auth }))
  localStorage.setItem(STORAGE_KEY, JSON.stringify({ auth }))
}
