// TODO: how to get types correct within `require.context`
import { App, Component } from 'vue'

const requireAll = (context: __WebpackModuleApi.RequireContext) =>
  context.keys().map(context) as Array<{ default: Component }>

const component = require.context('.', true, /\.vue$/)

console.log(requireAll(component))

export function registerGlobComponent(app: App): App {
  requireAll(component).forEach((item) => {
    app.component(item.default.name as string, item.default)
  })
  return app
}
