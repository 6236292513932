
import { defineComponent, onUpdated, watch } from 'vue'
import { useRoute } from 'vue-router'
import { syncAuth } from '@/utils/adapter'
export default defineComponent({
  setup() {
    const route = useRoute()
    watch(
      () => route,
      (to) => {
        // 百度统计
        // 百度统计关闭了自动PV跟踪
        ;(window as any)._hmt.push(['_setAutoPageview', false])
        // 百度统计默认截断#号后面的参数，故通过手动来添加pv
        ;(window as any)._hmt.push(['_trackPageview', '/#' + to.fullPath])
      },
      {
        deep: true,
      }
    )
    // 同步老 ifly-mall-h5  登录信息到 ifly-mall-webapp
    syncAuth()
    onUpdated(() => {
      // 同步老 ifly-mall-h5  登录信息到 ifly-mall-webapp
      syncAuth()
    })
  },
})
