<template>
  <div class="empty">
    <img class="img" :src="require('@/assets/images/empty@3x.png')" alt="" />
    <div class="message">{{ message }}</div>
    <slot name="opts">
      <div class="btn" @click="go">前往商城</div>
    </slot>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { getShopUrl } from '@/utils/adapter'

export default defineComponent({
  name: 'Empty',

  props: {
    message: {
      type: String as PropType<string>,
      default: '暂无数据',
    },
  },

  setup() {
    const go = () => {
      // location.href = 'https://xunfei.cn/h5'
      const url = getShopUrl()
      location.href = url
    }
    return {
      go,
    }
  },
})
</script>

<style lang="less" scoped>
.empty {
  .img {
    display: block;
    width: 400px;
    margin: 0 auto;
  }
  .message {
    margin-top: 46px;
    text-align: center;
    font-size: 28px;
    color: #666;
  }
  .btn {
    color: #f34142;
    width: 100px;
    text-align: center;
    margin: 10px auto;
    font-size: 24px;
    text-decoration: underline;
  }
}
</style>
