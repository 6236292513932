import { MutationTree } from 'vuex'
import { State, MutationTypes } from './types'
import { clearStore } from '@/utils/auth'

export interface Mutations {
  [MutationTypes.LOGIN](state: State, payload: any): void
  [MutationTypes.LOGOUT](state: State): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.LOGIN](state: State, payload: any) {
    state.token = payload.token
    state.user = payload.user
  },
  [MutationTypes.LOGOUT](state: State) {
    state.token = ''
    state.user = null
    clearStore()
  },
}
