import { STORAGE_KEY } from '@/constants'

export function getToken(): string {
  const local = localStorage.getItem(STORAGE_KEY)
  if (local === null) return ''
  return JSON.parse(local)?.auth?.token || ''
}

export function clearStore() {
  localStorage.removeItem(STORAGE_KEY)
}
