
import { defineComponent, computed } from 'vue'
import { props } from './props'
import '@/assets/fonts/iconfont'
export default defineComponent({
  name: 'SvgIcon',

  props,

  setup(props) {
    const iconName = computed(() => `#${props.iconClass}`)

    return {
      iconName,
    }
  },
})
