import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store, key } from './store'
import { registerGlobComponent } from '@/components'
import '@/assets/fonts/iconfont.css'

const app = createApp(App)

registerGlobComponent(app)

app.use(store, key).use(router).mount('#app')
