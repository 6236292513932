import request from '@/request'
import { Params } from './types'

// 发送短信验证码
export const sendSmsCode = (mobile: string) =>
  request.get(`/member/user/verification/code/${mobile}`)

// 用户短信验证码登录
export const memberLogin = (params: Params) => request.post('/member/user/login', params)

// 获取微信h5权限验证配置
export const getWxConfig = (params: Params) =>
  request.post('/member/weChat/ecological/share/H5', params)
