import { RouteRecordRaw } from 'vue-router'
export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'root',
    redirect: '/coupon-landing',
  },
  {
    path: '/coupon-landing',
    name: 'coupon-landing',
    meta: {
      title: '优惠券落地页',
    },
    component: () =>
      import(/* webpackChunkName: "coupon-landing" */ '@/views/coupon-landing/index.vue'),
  },
  {
    path: '/discount-landing',
    name: 'discount-landing',
    meta: {
      title: '优惠品落地页',
    },
    component: () =>
      import(/* webpackChunkName: "discount-landing" */ '@/views/discount-landing/index.vue'),
  },
  {
    path: '/lottery',
    name: 'lottery',
    meta: {
      title: '抽奖专区',
    },
    component: () => import(/* webpackChunkName: "lottery" */ '@/views/lottery/index.vue'),
  },
  {
    path: '/lottery/mine',
    name: 'lottery-mine',
    meta: {
      title: '我的奖品',
    },
    component: () => import(/* webpackChunkName: "lottery-mine" */ '@/views/lottery/mine.vue'),
  },
  {
    path: '/input-method',
    name: 'input-method',
    redirect: '/input-method/guide',
    meta: {
      title: '如何让小飞飞对你敞开心扉',
    },
    component: () =>
      import(/* webpackChunkName: "input-method" */ '@/views/input-method/index.vue'),
  },
  {
    path: '/input-method/guide',
    name: 'input-method-guide',
    meta: {
      title: '动动手指领话费、现金红包',
    },
    component: () =>
      import(/* webpackChunkName: "input-method-guide" */ '@/views/input-method/guide.vue'),
  },
]
