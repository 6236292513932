
import { defineComponent, ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { getLotteryInfo, doLottery } from '@/api/lottery'
import { goOldAuth } from '@/utils/adapter'
import { useStore } from '@/store'
import { Toast } from 'vant'
// 洗牌算法
const _shuffle = (arr: Recordable[]): Recordable[] => {
  let len = arr.length
  while (len) {
    const index = Math.floor(Math.random() * len--)
    const cur = arr[len]
    arr[len] = arr[index]
    arr[index] = cur
  }
  return arr
}

export default defineComponent({
  name: 'FlipCard',

  props: {},

  emits: ['opened'],
  setup(props, context) {
    // const { emit } = useContext()
    const route = useRoute()
    const store = useStore()
    // 奖项列表
    const rewards = ref<Recordable[]>([])
    const title = ref('')
    // 剩余抽奖次数
    const lastTimes = ref(0)
    // 有效期
    const validMsg = ref('')
    // 1后台设置了分享不增加抽奖次数 0相反
    const noShare = ref(0)

    // 是否所有卡牌都是合上的
    const allClosed = computed(() => rewards.value.every((item) => !item.opened))
    // 是否所有卡牌都是翻开的
    const allOpened = computed(() => rewards.value.every((item) => item.opened))

    const fetchInfo = () => {
      const _type = 2 // 1PC端 2移动端
      getLotteryInfo(route.query.code as string, _type)
        .then((res) => {
          const { data } = res.data
          validMsg.value = data.validMsg
          title.value = data.luckyDrawSlogen
          lastTimes.value = Number(data.participationConstraint)
          rewards.value = data.luckyDrawPrizeList.map((item: any) => {
            return {
              id: item.luckyDrawPrizeId,
              name: item.prizeName,
              img: item.prizeImg,
              opened: true,
            }
          })
          noShare.value = data.noShare
        })
        .catch(() => {})
    }

    // 全部合上
    const closeAll = () => {
      rewards.value = rewards.value.map((item) => {
        item.opened = false
        return item
      })
    }

    // 全部翻开
    const openAll = () => {
      rewards.value = rewards.value.map((item) => {
        item.opened = true
        return item
      })
    }

    // 翻开某个
    const openItem = (index: number) => {
      if (!store.state.auth.token) {
        console.log('未登录')
        goOldAuth()
        return
      }
      if (!allClosed.value) {
        return
      }
      if (lastTimes.value === 0) {
        // Toast('抽奖次数已用完啦，分享活动可再获得抽奖机会哦～')
        Toast(
          noShare.value == 1 ? '抽奖次数已用完啦' : '抽奖次数已用完啦，分享活动可再获得抽奖机会哦～'
        )
        return
      }
      openItemRequest().then((res) => {
        const { id } = res
        openItemAction(index, id)
        context.emit('opened', res)
      })
    }

    // 翻牌接口请求
    const openItemRequest = (): Promise<{
      luckyDrawWinningRecordLogId: number
      id: number
      type: number
      name: string
    }> => {
      return doLottery(route.query.code as string)
        .then((res) => {
          const { data } = res.data
          lastTimes.value = Number(data.remainingNumber)
          return {
            luckyDrawWinningRecordLogId: data.luckyDrawWinningRecordLogId as number,
            id: data.luckyDrawPrizeId as number,
            type: data.prizeType as number,
            name: data.prizeName as string,
          }
        })
        .catch(() => {
          return Promise.reject()
        })
    }

    // 翻开某个的动作
    const openItemAction = (index: number, id: number) => {
      const rewardsCopy = rewards.value.slice()
      const originalTarget = JSON.parse(JSON.stringify(rewardsCopy[index]))
      // 接口请求返回的奖品对应的索引
      const newIndex = rewards.value.findIndex((item) => item.id === id) as number
      // 需要把这两个索引对换过来，不然用户点击的是这个，翻开的是另一个
      const target = JSON.parse(
        JSON.stringify(rewards.value.find((item) => item.id === id))
      ) as Recordable
      target.opened = true
      rewardsCopy[newIndex] = originalTarget
      rewardsCopy[index] = target
      rewards.value = rewardsCopy
      // 翻开所有
      // openAll()
    }

    // 洗牌
    const shuffle = () => {
      rewards.value = _shuffle(rewards.value.slice())
    }

    // 点击中间的按钮
    const handleBtnClick = () => {
      if (!allClosed.value) {
        // 洗牌
        console.log('洗牌')
        closeAll()
        setTimeout(() => {
          shuffle()
        }, 500)
      }
    }

    fetchInfo()

    return {
      rewards,
      title,
      lastTimes,
      validMsg,
      allClosed,
      allOpened,
      fetchInfo,
      closeAll,
      openAll,
      openItem,
      openItemRequest,
      openItemAction,
      shuffle,
      handleBtnClick,
    }
  },
})
