
import { defineComponent, PropType, computed } from 'vue'
import { CouponType } from './types'

export default defineComponent({
  name: 'Coupon',

  props: {
    preferentialType: {
      type: String as PropType<CouponType>,
    },
    preferential: {
      type: [Number, String] as PropType<number | string>,
    },
    threshold: {
      type: String as PropType<string>,
    },
    validity: {
      type: String as PropType<string>,
    },
    applicableProducts: {
      type: Array as PropType<string[]>,
    },
    couponName: {
      type: String as PropType<string>,
    },
  },

  setup(props) {
    // 优惠券类型是否为满减券
    const isFullReduction = computed(() => props.preferentialType === CouponType.fullReduction)

    return {
      isFullReduction,
    }
  },
})
